<template>
  <div class="home">
    <div class="wrapper" ref="slideY">
      <div class="content">
        <div class="slide-page">
          <img class="slide-page-bg" src="//cdn.yueshare.cn/mlab/ibg.jpg" />
          <div class="t">
            <div class="hd"><p>全球创新 中国引擎</p></div>
            <div class="bd">
              <p>以创新的无限可能，加速实现商业增长</p>
              <p>创造高额回报</p>
            </div>
          </div>
        </div>
        <div class="slide-page">
          <!-- 创新加速器简介 -->
          <div class="t t1">
            <div class="bd">
              <P>美敦力</P>
            </div>
            <div class="hd">创新加速器简介</div>
            <div class="hr-bottom"></div>
          </div>
          <div class="c">
            <div class="hd">
              <div class="hd_img">
                <img src="//cdn.yueshare.cn/mlab/i_jj01.jpg" />
              </div>
            </div>
            <div class="bd">
              <p>
                美敦力创新加速器是美敦力设立在中国上海的一个创新孵化加速平台，其目的是促进更多医疗技术在中国市场转化为有价值的医疗产品和服务，成为可供患者选择的、高效的健康解决方案，同时为中国推进建设全球科技创新中心打造可持续的发展力。
              </p>
            </div>
            <div class="ft">
              <router-link to="about">
                <div class="btn-more">点击查看更多</div>
              </router-link>
            </div>
          </div>
        </div>
        <div class="slide-page">
          <!-- 空岛科技简介 -->
          <div class="t t1">
            <div class="bd">
              <P>战略合作企业</P>
            </div>
            <div class="hd">空岛科技简介</div>
            <div class="hr-bottom"></div>
          </div>
          <div class="c">
            <div class="hd">
              <div class="hd_img">
                <img src="//cdn.yueshare.cn/mlab/i_kdjj01.jpg" />
              </div>
            </div>
            <div class="bd">
              <p>
                空岛信息科技（上海）有限公司，成立于2016年是一家AR/VR企业级技术研发专业服务机构。核心团队来自上海交大，上海水晶石，腾讯，嘉会医院，奥美。公司致力于将增强现实(AR)与虚拟现实(VR)技术更好的应用和服务于医疗、工业及教育行业...
              </p>
            </div>
            <div class="ft">
              <router-link to="kdAbout">
                <div class="btn-more">点击查看更多</div>
              </router-link>
            </div>
          </div>
        </div>
        <div class="slide-page">
          <!-- 创业之路访谈 -->
          <QA></QA>
        </div>
        <div class="slide-page">
          <!-- 创业之路访谈 -->
          <Product></Product>
        </div>
        <div class="slide-page">
          <!-- CFO谈创新加速器 -->
          <TalkAboutCX></TalkAboutCX>
        </div>
        <div class="slide-page">
          <!-- 创新加速器领导谈空岛科技 -->
          <TalkAboutKD></TalkAboutKD>
        </div>
        <div class="slide-page">
          <!-- VR -->
          <div class="t t1">
            <div class="bd">
              <P>美敦力</P>
            </div>
            <div class="hd">创新加速器VR展示</div>
            <div class="hr-bottom"></div>
          </div>
          <div class="c">
            <div class="hd hd2">
              <div class="hd_img">
                <a
                  href="https://www.expoon.com/e/4m5jrmhwe6c/panorama?from=singlemessage&isappinstalled=0"
                >
                  <img
                    src="//cdn.yueshare.cn/mlab/e27a6d4db50c02761a54803f7199a75.png"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="slide-page">
          <!-- MAP -->
          <div class="t t1">
<!--            <div class="bd">-->
<!--              <P>美敦力</P>-->
<!--            </div>-->
            <div class="hd">美敦力创新加速器</div>
            <div class="hr-bottom"></div>
          </div>
          <div class="c c1">
            <div class="hd hd3">
                <div class="map" ref="map"></div>
            </div>
            <div class="ft">
              <p><b>美敦力创新加速器</b></p>
            </div>
            <div class="bd">
              <p>上海市闵行区陈行路2388号浦江科技广场3号楼4楼</p>
              <p>电话：021-33283701</p>
              <p>邮编：201114</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <span
      class="btn-toggle nextPage"
      :class="{ nextPage2: currentPageIndex > 0 }"
      @click="onNextPage"
      v-if="currentPageIndex!== 'undefind' && currentPageIndex  < 8"
    ></span>
  </div>
</template>

<script>
import BScroll from "better-scroll";
import Slide from "@better-scroll/slide";
import MouseWheel from "@better-scroll/mouse-wheel";
import QA from "./QA";
import Product from "./Product";
import TalkAboutCX from "./TACX";
import TalkAboutKD from "./TAKD";

BScroll.use(Slide);
BScroll.use(MouseWheel);

export default {
  name: "Home",
  components: {
    QA,
    Product,
    TalkAboutCX,
    TalkAboutKD,
  },
  data() {
    return {
      currentPageIndex: 0,
    };
  },
  methods: {
    slideInit() {
      window.slide = this.slide = new BScroll(this.$refs.slideY, {
        scrollY: true,
        scrollX: false,
        slide: {
          threshold: 100,
          loop: false,
          autoplay: false,
        },
        useTransition: false,
        momentum: false,
        bounce: true,
        swipeTime: 5000,
        stopPropagation: true,
        click: true,
        mouseWheel: {
          discreteTime: 0,
        },
      });
      this.slide.on("scrollEnd", this._onScrollEnd);
      this.slide.on("refresh", this._onRefresh)
    },
    _onRefresh(){
      console.log('refresh')
    },

    _onScrollEnd() {
      let pageIndex = this.slide.getCurrentPage().pageY;
      this.currentPageIndex = pageIndex;
      if (pageIndex > 0) {
        this.$emit("getMessage", false);
      } else {
        this.$emit("getMessage", true);
      }
    },
    onNextPage() {
      this.slide.next();
    },
    initMap() {
      let center = new window.qq.maps.LatLng(31.097614,121.506914);
      let map = new window.qq.maps.Map(this.$refs.map, {
        center: center,
        zoom: 18,
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        panControl:false
      });

      let marker = new window.qq.maps.Marker({
        position: center,
        map: map
      })

      marker.setAnimation(window.qq.maps.MarkerAnimation.DROP);
    }
  },
  mounted() {
    this.$emit("getMessage", true);
    this.slideInit();
    this.initMap();
  },
};
</script>

<style lang="less" scoped>
.home {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 0;
}
</style>
