<template>
  <div class="home">
    <div class="t t1">
      <div class="bd">
        <P>空岛科技</P>
      </div>
      <div class="hd">创新产品展示</div>
      <div class="hr-bottom"></div>
    </div>
    <div class="c">
<!--      <div class="dots">0{{ currentPageIndex + 1 }}</div>-->
      <div class="wrapper1" ref="q">
        <div class="content">
          <div class="slide-page" v-for="(i, index) in qas" :key="index">
            <router-link :to="'detail/' + i.u">
              <div class="hd hd1">
                <div class="hd_img">
                  <img :src="i.i" :alt="i.t" />
                </div>
              </div>
              <div class="ft">
                <p>{{ i.t }}</p>
              </div>
              <div class="bd">
                <p>{{ i.c }}</p>
              </div>
            </router-link>
          </div>
        </div>
      </div>

      <div class="toolbar">
        <v-icon light large :disabled="!currentPageIndex" @click="prePage">mdi-chevron-left</v-icon>
        <v-icon light large :disabled='currentPageIndex ==qas.length -1' @click="nextPage">mdi-chevron-right</v-icon>
      </div>
    </div>
  </div>
</template>

<script>
import BScroll from "better-scroll";
import Slide from "@better-scroll/slide";
import tdata from '../../static/data.json';

BScroll.use(Slide);

export default {
  name: "product",
  data() {
    return {
      currentPageIndex: 0,
      qas: tdata.p
    };
  },
  methods: {
    qSlideInit() {
      window.qSlide = this.qSlide = new BScroll(this.$refs.q, {
        scrollY: false,
        scrollX: true,
        slide: {
          threshold: 100,
          loop: false,
          autoplay: false,
        },
        useTransition: false,
        momentum: false,
        bounce: false,
        stopPropagation: true,
        click: true,
      });
      this.qSlide.on("scrollEnd", this._onScrollEnd);
    },
    
    _onScrollEnd() {
      let pageIndex = this.qSlide.getCurrentPage().pageX;
      this.currentPageIndex = pageIndex;
    },
    nextPage(){
      this.qSlide.next();
    },
    prePage(){
      this.qSlide.prev()
    }
  },
  mounted() {
    this.$emit("getMessage", false);
    this.qSlideInit();
  },
};
</script>

<style lang="less" scoped>
.home {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 0;
}
</style>